import { useAppContext } from 'context';
import { useAdminRelationships } from 'hooks';

export type UseConcludeRelationshipParams = {};

export function useConcludeRelationship() {
  const { reqAdminRelationships } = useAdminRelationships({
    getSilently: false,
  });

  const { organizationSlug, isRootDomain } = useAppContext();

  async function concludeRelationship(relationshipId: string) {
    const requestUrl = isRootDomain
      ? `/superadmin/relationships/${relationshipId}`
      : `/admin/relationships/${relationshipId}`;
    const nextRelationshipBody = {
      isConcluded: true,
    };
    await reqAdminRelationships({
      method: 'PATCH',
      url: requestUrl,
      data: nextRelationshipBody,
      params: {
        ...(organizationSlug && { organizationSlug }),
      },
    });
  }

  return { concludeRelationship };
}
