import { useAppContext } from 'context';
import { useAdminProfileProgramOverviews } from 'hooks/admin';
import { useCallback, useEffect, useState } from 'react';

export type ProfileProgramOverviewFetcherParams = {
  profileId?: string;
  autoFetch?: boolean;
};

export function useProfileProgramOverviewFetcher({
  profileId: autoFetchProfileId,
  autoFetch = false,
}: ProfileProgramOverviewFetcherParams) {
  const { organizationSlug, isRootDomain } = useAppContext();
  const {
    adminProfileProgramOverviews,
    isLoadingAdminProfileProgramOverviews:
      getLoadingAdminProfileProgramOverviews,
    isErrorAdminProfileProgramOverviews: getErrorAdminProfileProgramOverviews,
    reqAdminProfileProgramOverviews,
  } = useAdminProfileProgramOverviews({
    getSilently: false,
    getSilentlyUrl: isRootDomain
      ? '/superadmin/organizations'
      : '/organizations',
    queryParams: {
      ...(organizationSlug && { organizationSlug }),
    },
  });
  const isLoadingAdminProfileProgramOverviews =
    getLoadingAdminProfileProgramOverviews();
  const isErrorAdminProfileProgramOverviews =
    getErrorAdminProfileProgramOverviews();

  const fetchProfileProgramOverviews = useCallback(async () => {
    const profileIdToFetch = autoFetchProfileId;
    if (!profileIdToFetch) return;
    try {
      const requestUrl = !isRootDomain
        ? '/admin/profileProgramOverviews'
        : '/superadmin/profileProgramOverviews';
      await reqAdminProfileProgramOverviews({
        method: 'GET',
        url: requestUrl,
        params: {
          profileId: profileIdToFetch,
          ...(organizationSlug && { organizationSlug: organizationSlug }),
        },
      });
    } catch (err: unknown) {
      console.error('AdminUser Lookup Failed', { err });
    }
  }, [
    autoFetchProfileId,
    isRootDomain,
    organizationSlug,
    reqAdminProfileProgramOverviews,
  ]);

  const [fetchedFor, setFetchedFor] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!autoFetch) return;
    if (!autoFetchProfileId) return;
    if (fetchedFor === autoFetchProfileId) return;
    if (isLoadingAdminProfileProgramOverviews) return;
    setFetchedFor(autoFetchProfileId);
    fetchProfileProgramOverviews();
  }, [
    autoFetch,
    fetchedFor,
    fetchProfileProgramOverviews,
    autoFetchProfileId,
    isLoadingAdminProfileProgramOverviews,
  ]);

  const profileProgramOverviews = adminProfileProgramOverviews();
  const areProfileProgramOverviewsFetching =
    isLoadingAdminProfileProgramOverviews;
  const encounteredProfileProgramOverviewsRequestError =
    isErrorAdminProfileProgramOverviews;

  return {
    fetchProfileProgramOverviews,
    profileProgramOverviews,
    areProfileProgramOverviewsFetching,
    encounteredProfileProgramOverviewsRequestError,
  };
}
