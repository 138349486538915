import { firstLetterUppercase } from '@guider-global/front-end-utils';
import { ProgramVariation } from '@guider-global/shared-types';
import { useAppContext } from 'context';
import { useAdminManualMatch } from 'hooks';

export type CreateManualMatchParams = {
  role: 'guide' | 'trainee';
  userProfileId?: string;
  manualMatchProfileId?: string;
  userEmail?: string;
  manualMatchEmail?: string;
  programSlug: string;
  programTypeSlug: string;
  programVariationTypeSlug: ProgramVariation;
};

export type CreateManualMatchResult =
  | {
      status: 'error';
      message: string;
    }
  | { status: 'success' };

export function useManualMatchController() {
  const { organizationSlug, isRootDomain } = useAppContext();

  const { isLoadingAdminManualMatch, reqAdminManualMatch } =
    useAdminManualMatch({
      forceRefresh: false,
      getSilently: false,
    });
  const isManualMatchRequestPending = isLoadingAdminManualMatch();

  async function createManualMatch({
    role,
    userEmail,
    manualMatchEmail,
    userProfileId,
    manualMatchProfileId,
    programSlug,
    programTypeSlug,
    programVariationTypeSlug,
  }: CreateManualMatchParams): Promise<CreateManualMatchResult> {
    const relationshipEmails =
      role === 'trainee'
        ? {
            traineeEmails: [userEmail],
            guideEmails: [manualMatchEmail],
          }
        : {
            traineeEmails: [manualMatchEmail],
            guideEmails: [userEmail],
          };
    const relationshipProfileIds =
      role === 'trainee'
        ? {
            traineeProfileIds: [userProfileId],
            guideProfileIds: [manualMatchProfileId],
          }
        : {
            traineeProfileIds: [manualMatchProfileId],
            guideProfileIds: [userProfileId],
          };
    const response = await reqAdminManualMatch({
      method: 'POST',
      url: isRootDomain ? '/superadmin/manualMatch' : `/admin/manualMatch`,
      data: {
        ...(userProfileId && manualMatchProfileId
          ? relationshipProfileIds
          : []),
        ...(userEmail && manualMatchEmail ? relationshipEmails : []),
        ...(organizationSlug && { organizationSlug }),
        programSlug,
        programVariationTypeSlug,
        programTypeSlug,
      },
    });
    if (response.status === 'error') {
      const message = firstLetterUppercase(
        response.errors?.at(0)?.message ?? '',
      );
      return { status: 'error', message };
    }
    return { status: 'success' };
  }

  return { createManualMatch, isManualMatchRequestPending };
}
