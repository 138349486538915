import { getSubDomain } from '@guider-global/front-end-utils';
import { useAdminMemberships } from 'hooks';
import { getMembershipProfileStatusFlags } from './functions';
import { MembershipProfileStatus } from 'views';
import { useAppContext } from 'context';

export * from './functions';
export * from './types';

export function useUpdateMembershipStatus() {
  const { reqAdminMemberships } = useAdminMemberships({
    getSilently: false,
  });
  const { organizationSlug, isRootDomain } = useAppContext();

  async function updateMembershipProfileStatus(
    membershipId: string,
    nextProfileStatus: MembershipProfileStatus,
  ) {
    const requestUrl = isRootDomain
      ? `/superadmin/memberships/${membershipId}`
      : `/admin/memberships/${membershipId}`;
    const profileStatusFlags = getMembershipProfileStatusFlags({
      profileStatus: nextProfileStatus,
    });
    await reqAdminMemberships({
      method: 'PATCH',
      url: requestUrl,
      data: profileStatusFlags,
      params: {
        ...(organizationSlug && { organizationSlug }),
      },
    });
  }

  return { updateMembershipProfileStatus };
}
