import {
  ICustomField,
  IError,
  IMembership,
  IProfile,
  ISkill,
} from '@guider-global/shared-types';
import {
  Button,
  ContentModal,
  IProfileViewField,
  ProfileView,
  HideableCard,
  Stack,
  Loading,
  AlertBox,
} from '@guider-global/ui';
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import { useAdminMatches, useAdminUserProfiles } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import i18nIsoCountries from 'i18n-iso-countries';
import { usePictureMulti } from '@guider-global/azure-storage-hooks';
import { useAppContext } from 'context';

export type SuggestedMatchesContainerProps = {
  programSlug: string;
  onCreateManualMatch: (params: { manualMatchProfileId: string }) => void;
  isManualMatchRequestPending: boolean;
};

export function SuggestedMatchesContainer({
  programSlug,
  onCreateManualMatch,
  isManualMatchRequestPending,
}: SuggestedMatchesContainerProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { adminUserProfiles } = useAdminUserProfiles({});
  const { userId, firstName, lastName } = adminUserProfiles()[0];
  const { organizationSlug, isRootDomain } = useAppContext();

  function handleModalOpen() {
    reqAdminMatches({
      method: 'GET',
      url: isRootDomain ? '/superadmin/matches' : '/admin/matches',
      params: {
        ...(organizationSlug && { organizationSlug }),
        programSlug,
        userId,
      },
    });
    setIsModalOpen(true);
  }
  function handleModalClose() {
    setIsModalOpen(false);
  }

  useEffect(() => {
    if (!isManualMatchRequestPending) {
      handleModalClose();
    }
  }, [isManualMatchRequestPending]);

  const {
    isLoadingAdminMatches,
    adminMatches,
    reqAdminMatches,
    getErrorsAdminMatches,
    isErrorAdminMatches,
  } = useAdminMatches({
    forceRefresh: false,
    getSilently: false,
  });

  if (isErrorAdminMatches()) {
    throw new Error(
      `Could not find suggested matches: ${getErrorsAdminMatches()
        .flatMap((error: IError) => error.message)
        .join('/n')}`,
    );
  }

  const matches = adminMatches();

  const allPictures = useMemo(() => {
    return matches.map((match) => {
      const membership = match.membership as IMembership;
      const { profile } = membership;
      const { id: profileId, picture } = profile as IProfile;
      return {
        pictureId: profileId,
        sasTokenApiPath: '/admin/storage',
        containerName: 'pictures',
        pictureBlobName: picture,
      };
    });
  }, [matches]);

  const { picture: avatarSrcs } = usePictureMulti({
    pictureParamsList: allPictures,
  });

  const parsedMatches = useMemo(() => {
    return matches.map((match) => {
      const { percentage, membership } = match;
      const { profile, programFields, skills } = membership as IMembership;
      const {
        id: profileId,
        firstName,
        lastName,
        country,
        townOrCity,
        jobTitle,
        linkedInUrl,
      } = profile as IProfile;

      const picture = avatarSrcs[profileId];

      const countryName = country
        ? i18nIsoCountries.getName(country, 'en')
        : undefined;

      const location = `${countryName ?? ''}${
        countryName && townOrCity ? ', ' : ''
      }${townOrCity ?? ''}`;

      const score = percentage ? Number(percentage.toFixed(2)) : 0;

      const skillsTyped = skills as ISkill[];
      const skillValues = skillsTyped.map((skill) => skill.fieldSlug);

      const programFieldsTyped = programFields as ICustomField[];
      const programFieldsParsed = programFieldsTyped
        .map((field) => {
          return {
            type: field.fieldType,
            label: field.fieldSlug,
            value: field.value,
          };
        })
        .filter((field) => field.type !== 'check') as IProfileViewField[];

      return {
        profileId,
        firstName,
        lastName,
        location,
        linkedInUrl,
        avatarSrc: picture ?? '',
        jobTitle,
        score,
        skills: { label: 'Skills offered', value: skillValues },
        customFields: programFieldsParsed,
      };
    });
  }, [matches, avatarSrcs]);

  if (isModalOpen) {
    return (
      <>
        <Button
          variant="textWithIcon"
          startIcon={<PeopleAlt />}
          onClick={handleModalOpen}
          label="Suggested relationships"
        />
        <ContentModal
          icon={<PeopleAlt />}
          heading="Suggested relationships"
          descriptions={[`For ${firstName} ${lastName}`]}
          open={isModalOpen}
          isLoading={isManualMatchRequestPending}
          onClose={handleModalClose}
          primaryButtonProps={{
            label: 'Cancel',
            variant: 'outlined',
            onClick: handleModalClose,
            disabled: isManualMatchRequestPending,
          }}
        >
          {!isLoadingAdminMatches() && parsedMatches.length === 0 ? (
            <AlertBox
              variant="icon"
              title="No suggested matches found"
              iconVariant="info"
            />
          ) : (
            <></>
          )}
          {isLoadingAdminMatches() ? (
            <Loading isLoading={true} sx={{ height: '100%' }} />
          ) : (
            <></>
          )}
          <Stack direction="column" spacing={3} width="100%">
            {matches.length !== 0 &&
              !isLoadingAdminMatches() &&
              parsedMatches.map((match) => (
                <HideableCard
                  key={`${match.firstName}-${match.lastName}-card`}
                  hideButton={{ label: 'Not Relevant' }}
                  unhideButtonLabel="Undo"
                  hiddenBarTitle="Profile Hidden"
                  otherButtons={[
                    {
                      label: 'Create relationship',
                      variant: 'contained',
                      onClick: () =>
                        onCreateManualMatch({
                          manualMatchProfileId: match.profileId,
                        }),
                      loading: isManualMatchRequestPending,
                    },
                  ]}
                >
                  <ProfileView
                    firstName={match.firstName}
                    lastName={match.lastName}
                    score={match.score}
                    jobTitle={match.jobTitle}
                    location={match.location}
                    avatarSrc={match.avatarSrc}
                    skills={match.skills}
                    linkedInUrl={match.linkedInUrl}
                    customFields={match.customFields}
                  />
                </HideableCard>
              ))}
          </Stack>
        </ContentModal>
      </>
    );
  } else {
    return (
      <Button
        variant="textWithIcon"
        startIcon={<PeopleAlt />}
        onClick={handleModalOpen}
        label="Suggested relationships"
      />
    );
  }
}
