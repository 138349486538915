import { MembershipProfileStatus } from 'views';
import { MembershipProfileStatusFlags } from './types';

export type GetMembershipProfileStatusFlagsParams = {
  profileStatus: MembershipProfileStatus;
};
export function getMembershipProfileStatusFlags({
  profileStatus,
}: GetMembershipProfileStatusFlagsParams): MembershipProfileStatusFlags {
  if (profileStatus === 'listed') {
    return {
      isPublished: true,
      isBlocked: false,
      requiresApproval: false,
    };
  }
  if (profileStatus === 'unlisted') {
    return {
      isPublished: false,
      isBlocked: false,
      requiresApproval: false,
    };
  }
  if (profileStatus === 'blocked') {
    return {
      isPublished: false,
      isBlocked: true,
      requiresApproval: false,
    };
  }
  if (profileStatus === 'requires-approval') {
    return {
      isPublished: false,
      isBlocked: false,
      requiresApproval: true,
    };
  }
  return {};
}
