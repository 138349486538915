import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  UsePictureParams,
  usePictureMulti,
} from '@guider-global/azure-storage-hooks';
import { ProfileProgramView } from 'views';
import {
  useConcludeRelationship,
  useProfileProgramOverviewFetcher,
  useUpdateMembershipStatus,
} from './hooks';
import { MembershipProfileStatus } from 'views/ProfileProgramView/types';
import {
  CreateManualMatchParams,
  useManualMatchController,
} from './hooks/useManualMatchController';
import {
  NoticeSnackbar,
  NoticeSnackbarProps,
  Loading,
} from '@guider-global/ui';
import { useAdminUserProfiles } from 'hooks';

export type ProfileProgramOverviewListContainerProps = {
  profileId: string;
};

export function ProfileProgramOverviewListContainer({
  profileId,
}: ProfileProgramOverviewListContainerProps) {
  const navigate = useNavigate();

  // Fetch ProfileProgramOverviews
  const {
    profileProgramOverviews,
    fetchProfileProgramOverviews,
    areProfileProgramOverviewsFetching,
  } = useProfileProgramOverviewFetcher({
    profileId,
    autoFetch: true,
  });

  // Admin User Profiles
  const { adminUserProfiles, isLoadingAdminUserProfiles } =
    useAdminUserProfiles({});
  const userProfile = adminUserProfiles().at(0);
  const userDisplayName = userProfile?.displayName ?? 'N/A';
  const userEmail = userProfile?.email ?? 'N/A';

  // Relationship Profile Picture Params List
  const pictureParamsList = useMemo(() => {
    const relationshipProfiles = profileProgramOverviews
      .map((profileProgramOverview) => profileProgramOverview.memberships)
      .flat()
      .map((membership) => membership.relationshipProfiles)
      .flat();
    const pictureParamsList = relationshipProfiles.reduce<UsePictureParams[]>(
      (accumulatedPictureParamsList, relationshipProfile) => {
        const alreadyRegisteredProfileId =
          accumulatedPictureParamsList.find(
            (pictureParams) =>
              pictureParams.pictureId === relationshipProfile.id,
          ) !== undefined;
        if (alreadyRegisteredProfileId) return accumulatedPictureParamsList;
        const nextPictureParams = {
          pictureId: relationshipProfile.id,
          sasTokenApiPath: '/admin/storage',
          containerName: 'pictures',
          pictureUrl: relationshipProfile.auth0Picture,
          pictureBlobName: relationshipProfile.picture,
        };
        return [...accumulatedPictureParamsList, nextPictureParams];
      },
      [],
    );
    return pictureParamsList;
  }, [profileProgramOverviews]);
  const { picture: avatarSrcs } = usePictureMulti({ pictureParamsList });

  function getAvatarSrc(profileId: string) {
    return avatarSrcs[profileId] ?? undefined;
  }

  // Conclude Relationship
  const { concludeRelationship } = useConcludeRelationship();
  async function handleConcludeRelationship(relationshipId: string) {
    await concludeRelationship(relationshipId);
    await fetchProfileProgramOverviews();
  }
  function onConcludeRelationship(relationshipId: string) {
    handleConcludeRelationship(relationshipId);
  }

  // Navigate Profile
  function onNavigateProfile(profileId: string) {
    navigate(`/users/${profileId}`);
  }

  // Update Membership Status
  const { updateMembershipProfileStatus } = useUpdateMembershipStatus();
  async function handleUpdateMembershipProfileStatus(
    membershipId: string,
    nextProfileStatus: MembershipProfileStatus,
  ) {
    await updateMembershipProfileStatus(membershipId, nextProfileStatus);
    await fetchProfileProgramOverviews();
  }
  function onUpdateMembershipProfileStatus(
    membershipId: string,
    nextProfileStatus: MembershipProfileStatus,
  ) {
    handleUpdateMembershipProfileStatus(membershipId, nextProfileStatus);
  }

  // Manual Match Alert
  const [manualMatchAlert, setManualMatchAlert] = useState<
    Pick<NoticeSnackbarProps, 'show' | 'variant' | 'title' | 'message'>
  >({
    show: false,
    variant: 'success',
    title: '',
    message: '',
  });
  function handleManualMatchAlertClose() {
    setManualMatchAlert({
      show: false,
      variant: 'success',
      title: '',
      message: '',
    });
  }

  // Create Manual Match
  const { createManualMatch } = useManualMatchController();
  const [isManualMatchRequestPending, setManualMatchRequestPending] =
    useState<boolean>(false);

  async function handleCreateManualMatch(params: CreateManualMatchParams) {
    setManualMatchRequestPending(true);
    const result = await createManualMatch(params);
    if (result.status === 'error') {
      setManualMatchAlert({
        show: true,
        variant: 'error',
        title: 'Error',
        message: result.message,
      });
      setManualMatchRequestPending(false);
      return;
    }
    setManualMatchAlert({
      show: true,
      variant: 'success',
      title: 'Relationship created',
      message: 'The relationship has been created successfully',
    });
    await fetchProfileProgramOverviews();
    setManualMatchRequestPending(false);
  }

  if (areProfileProgramOverviewsFetching || isLoadingAdminUserProfiles()) {
    return <Loading sx={{ mt: '10vh', mb: '50vh' }} />;
  } else {
    return (
      <>
        {(profileProgramOverviews ?? []).map((profileProgramOverview) => (
          <ProfileProgramView
            key={profileProgramOverview.id}
            userEmail={userEmail}
            userProfileId={profileId}
            userDisplayName={userDisplayName}
            profileProgramOverview={profileProgramOverview}
            getAvatarSrc={getAvatarSrc}
            onConcludeRelationship={onConcludeRelationship}
            onNavigateProfile={onNavigateProfile}
            onUpdateMembershipProfileStatus={onUpdateMembershipProfileStatus}
            createManualMatch={handleCreateManualMatch}
            isManualMatchRequestPending={isManualMatchRequestPending}
          />
        ))}
        <NoticeSnackbar
          show={manualMatchAlert?.show}
          onClose={handleManualMatchAlertClose}
          variant={manualMatchAlert.variant}
          message={manualMatchAlert.message}
          title={manualMatchAlert.title}
        />
      </>
    );
  }
}
