import { useAuth } from '@guider-global/auth-hooks';
import {
  getOrgUrl,
  getOrigin,
  getSubDomain,
} from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useSanityBaseLanguage,
  useSanityOrganization,
} from '@guider-global/sanity-hooks';
import {
  Avatar,
  AvatarBox,
  AvatarButton,
  Button,
  ButtonProps,
  DropDown,
  Footer,
  GuiderAdminLogo,
  IconButton,
  ListItemButton,
  Loading,
  NavigationItem,
  Sidebar,
  SidebarItemChildProps,
  SidebarItemProps,
  ThemeWrapper,
  appPalette,
  theme,
} from '@guider-global/ui';
import {
  AppBar as MuiAppBar,
  Box as MuiBox,
  Toolbar as MuiToolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  IOrganization,
  IProgramDetails,
  KeyedProps,
} from '@guider-global/shared-types';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ListIcon from '@mui/icons-material/List';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useAppContext } from 'context';
import { useAdminPrograms } from 'hooks';
import { useOrganizations } from 'hooks/useOrganizations';
import { DashboardView } from 'views';

type AppThemeContainerProps = {
  children?: JSX.Element;
};

export function AppThemeContainer({ children }: AppThemeContainerProps) {
  const { REACT_APP_VERSION } = process.env;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const subdomain = getSubDomain();

  const { organizationSlug, isRootDomain, setOrganizationSlug } =
    useAppContext();

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));

  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState<boolean>(false);

  function isSidebarItemActive(route: string, child?: boolean) {
    if (child) {
      return pathname === route;
    }

    if (route === '/metrics') {
      return pathname.includes(route) && !pathname.includes('/metrics-beta');
    }
    return pathname.includes(route);
  }

  const { logout, isLoading, loginWithRedirect } = useAuth({
    redirectUriLogout: getOrigin() + '/login',
  });

  useSanityBaseLanguage({
    getSilently: true,
    organizationSlug: subdomain,
    localeCode: 'en_GB',
  });

  const { adminPrograms } = useAdminPrograms({
    queryParams: {
      organizationSlug: organizationSlug,
      localeCode: 'en_GB',
    },
    getSilentlyUrl: isRootDomain ? '/superadmin/programs' : `/admin/programs`,
    getSilently: true,
    forceRefresh: true,
  });

  const programs = adminPrograms();
  const programSlugs = programs.map((program) => program.programSlug);
  const programSlugSet = [...new Set(programSlugs)];
  const filteredPrograms = programSlugSet.map((programSlug) =>
    programs.find((program) => program.programSlug === programSlug),
  ) as IProgramDetails[];

  const {
    getOrganization,
    isLoadingSanityOrganization,
    refetchSanityOrganization,
  } = useSanityOrganization({
    getSilently: true,
    organizationSlug: subdomain,
    localeCode: 'en_GB',
  });

  const organizationPicture = getOrganization().white_label.auth0_logo;
  const organizationName = getOrganization().basic_info.name;

  const { organizations, isLoadingOrganizations } = useOrganizations({
    getSilently: true,
    getSilentlyUrl: isRootDomain ? '/superadmin/organizations' : undefined,
  });

  const organizationsList = organizations();

  const isLoadingPage =
    isLoading || isLoadingSanityOrganization() || isLoadingOrganizations();

  let primaryNavigation: KeyedProps<SidebarItemProps>[] = [
    // TODO Enable when dashboard is done
    // {
    //   text: 'Dashboard',
    //   label: 'dashboard',
    //    onClick: () => navigate('/dashboard'),
    //   icon: <DashboardIcon color='white" />,
    // },
    {
      key: 'metrics',
      label: 'Metrics',
      onClick: () => navigate('/metrics'),
      icon: <AutoGraphIcon sx={{ color: 'white' }} />,
      isActive: isSidebarItemActive('/metrics'),
      children: [
        {
          label: 'Overview',
          onClick: () => navigate(`/metrics`),
          isActive: isSidebarItemActive('/metrics', true),
        },
        ...filteredPrograms.map((program) => {
          const item: SidebarItemChildProps = {
            label: program.programName,
            isActive: isSidebarItemActive(
              `/metrics/${program.programSlug}`,
              true,
            ),
            onClick: () =>
              navigate(`/metrics/${program.programSlug}`, {
                state: { programName: program.programName },
              }),
          };
          return item;
        }),
      ],
    },
    {
      key: 'programs',
      label: 'Programs',
      onClick: () => navigate('/programs'),
      icon: <ListIcon sx={{ color: 'white' }} />,
      isActive: isSidebarItemActive('/programs'),
    },
    {
      key: 'users',
      label: 'Users',
      onClick: () => navigate('/users'),
      icon: <PeopleAltIcon sx={{ color: 'white' }} />,
      isActive: isSidebarItemActive('/users'),
    },
    {
      key: 'integrations',
      label: 'Integrations',
      onClick: () => navigate('/integrations'),
      icon: <IntegrationInstructionsIcon sx={{ color: 'white' }} />,
      isActive: isSidebarItemActive('/integrations'),
    },
  ];

  const sidebarMobileButtons: KeyedProps<ButtonProps>[] = [
    {
      key: 'log-out',
      label: 'Log out',
      onClick: () =>
        logout({ logoutParams: { returnTo: getOrigin() + '/login' } }),
      variant: 'text',
      color: 'light',
      startIcon: <LogoutOutlined />,
      style: { justifyContent: 'flex-start' },
    },
  ];

  const footerNavigation: NavigationItem[] = [
    {
      key: 'cookies-policy',
      text: 'Cookies Policy',
      href: `https://${organizationSlug}.guider.app/legal/cookies`,
    },
    {
      key: 'terms',
      text: 'Terms of Service',
      href: `https://${organizationSlug}.guider.app/legal/terms`,
    },
    {
      key: 'guider-privacy-policy',
      text: 'Guider Privacy Policy',
      href: `https://${organizationSlug}.guider.app/legal/privacy`,
    },
  ];

  if (isLoadingPage)
    return (
      <Loading
        withBackdrop={true}
        isLoading={isLoadingPage}
        color={appPalette.navigation.text.primary}
        sx={{ backgroundColor: appPalette.navigation.background.primary }}
      />
    );

  const handleOnClickOrganization = async (organization: IOrganization) => {
    if (isRootDomain) {
      setOrganizationSlug(organization.slug);
      refetchSanityOrganization(organization.slug, 'en_GB');
    } else {
      await loginWithRedirect({
        authorizationParams: {
          redirect_uri: getOrgUrl(organization.slug).toString(),
          organization: organization.id,
        },
      });
    }
  };

  return (
    <ThemeWrapper customTheme={theme.appTheme}>
      <DashboardView
        header={
          isMobile ? (
            <MuiAppBar
              position="static"
              sx={{ background: appPalette.navigation.background.primary }}
              elevation={0}
            >
              <MuiToolbar>
                <Avatar
                  size="small"
                  sx={{ objectFit: 'contain !important' }}
                  src={buildSanityImageUrl({ source: organizationPicture })}
                />
                <MuiBox
                  sx={{
                    flexGrow: 1,
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <GuiderAdminLogo size={'medium'} />
                </MuiBox>
                <IconButton
                  onClick={() => setIsMobileDrawerOpen(!isMobileDrawerOpen)}
                  variant="clear"
                  icon={<MenuIcon />}
                />
              </MuiToolbar>
            </MuiAppBar>
          ) : (
            <MuiBox
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: 3,
                backgroundColor: appPalette.paper.background.primary,
                borderBottom: `1px solid ${appPalette.paper.border.primary}`,
              }}
            >
              {isRootDomain ? (
                <AvatarButton
                  size={'small'}
                  sx={{ objectFit: 'contain !important' }}
                  avatarSrc={buildSanityImageUrl({
                    source: organizationPicture,
                  })}
                  heading={organizationName}
                  onClick={() => navigate('/organizations')}
                />
              ) : (
                <DropDown
                  anchor={
                    <AvatarButton
                      sx={{ img: { objectFit: 'contain !important' } }}
                      size={'small'}
                      avatarSrc={buildSanityImageUrl({
                        source: organizationPicture,
                      })}
                      heading={organizationName}
                    />
                  }
                  header="Switch Organization"
                >
                  {organizationsList.map((organization) => {
                    return (
                      <ListItemButton
                        isSelected={organization.slug === organizationSlug}
                        onClick={async () =>
                          await handleOnClickOrganization(organization)
                        }
                        key={organization.slug}
                      >
                        <AvatarBox
                          size="small"
                          avatarSrc={
                            organization.branding?.logoUrl
                              ? organization.branding?.logoUrl
                              : ''
                          }
                          heading={
                            organization.displayName
                              ? organization.displayName
                              : ''
                          }
                        />
                      </ListItemButton>
                    );
                  })}
                </DropDown>
              )}
              <Button
                label={'Log out'}
                onClick={() =>
                  logout({ logoutParams: { returnTo: getOrigin() + '/login' } })
                }
                variant="text"
                startIcon={<LogoutOutlined />}
              />
            </MuiBox>
          )
        }
        navigation={
          <Sidebar
            logo={
              <GuiderAdminLogo
                size={'large'}
                color={appPalette?.common?.white}
              />
            }
            navigationItems={primaryNavigation}
            mobileButtons={sidebarMobileButtons}
            isMobileDrawerOpen={isMobileDrawerOpen}
            onMobileDrawerClose={(
              event: Record<string, unknown>,
              reason: string,
            ) => reason === 'backdropClick' && setIsMobileDrawerOpen(false)}
          />
        }
        isMobile={isMobile}
        footer={
          <Footer
            items={[...footerNavigation]}
            copyrightText={`© ${new Date().getFullYear()} Guider Global Limited. All rights reserved.`}
            versionNumber={REACT_APP_VERSION ?? ''}
          />
        }
      >
        {children ?? <Outlet />}
      </DashboardView>
    </ThemeWrapper>
  );
}
